import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarIcon from '../assets/icons/sidebar.svg';
import SidebarDisableIcon from '../assets/icons/sidebar-disable.svg';
import TopbarIcon from '../assets/icons/topbar.svg';
import TopbarDisableIcon from '../assets/icons/topbar-disable.svg';
import RightSidebarIcon from '../assets/icons/right-sidebar.svg';
import RightSidebarDisableIcon from '../assets/icons/right-sidebar-disable.svg';
import FullscreenIcon from '../assets/icons/fullscreen.svg';
import FullscreenDisableIcon from '../assets/icons/fullscreen-disable.svg';
import CommentIcon from '../assets/icons/comment.svg';
import ResponseSheetIcon from '../assets/icons/response-sheet.svg';
import PlusIcon from '../assets/icons/plus.svg'; // Import PlusIcon
import { useSidebar } from '../context/SidebarContext';

interface Submission {
  id: string;
  name: string;
}

const SubmissionBar: React.FC = () => {
  const navigate = useNavigate();
  const {
    toggleSidebar,
    toggleTopbar,
    toggleFullScreen,
    toggleComment,
    isSidebarOpen,
    isTopbarOpen,
  } = useSidebar();

  // State to hold submissions
  const [submissions, setSubmissions] = useState<Submission[]>([
    { id: 'submission1', name: 'Submission 1' },
  ]);

  // State to track the active submission
  const [activeSubmission, setActiveSubmission] = useState<string>(localStorage.getItem("submission") || 'submission1');

  // Effect to initialize submissions from localStorage
  useEffect(() => {
    const storedSubmissions: Submission[] = [];

    // Iterate through all localStorage keys
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const match = key.match(/^submission(\d+)-page1$/);
        if (match) {
          const number = parseInt(match[1], 10);
          if (!isNaN(number) && number >= 2) { // Start from Submission 2
            storedSubmissions.push({
              id: `submission${number}`,
              name: `Submission ${number}`,
            });
          }
        }
      }
    }

    // Sort the submissions based on their number
    storedSubmissions.sort((a, b) => {
      const numA = parseInt(a.id.replace('submission', ''), 10);
      const numB = parseInt(b.id.replace('submission', ''), 10);
      return numA - numB;
    });

    // Update the submissions state by appending stored submissions
    if (storedSubmissions.length > 0) {
      setSubmissions((prev) => [...prev, ...storedSubmissions]);
    }

    // Optionally, initialize activeSubmission from localStorage
    const storedActive = localStorage.getItem('submission');
    if (storedActive && submissions.some(sub => sub.id === storedActive)) {
      setActiveSubmission(storedActive);
    }
  }, []);

  // Effect to persist activeSubmission to localStorage
  useEffect(() => {
    localStorage.setItem('submission', activeSubmission);
  }, [activeSubmission]);

  const handleSubmissionClick = (submissionKey: string) => {
    setActiveSubmission(submissionKey); // Update active submission
    const storedSubmission = localStorage.getItem(`${submissionKey}-page1`);
    const destination = storedSubmission ? '/playground' : '/documents';
    navigate(destination, { state: { submissionKey } });
  };

  const handleCommentClick = () => {
    // Dispatch a custom event to open the comments sidebar in Excalidraw
    window.dispatchEvent(new Event('toggleComment'));
  };

  const handleResponseSheetClick = () => {
    // Dispatch a custom event to open the response sheet in Excalidraw
    const event = new CustomEvent('toggleResponseSheet', {
      detail: { open: true }, // You can pass additional data if needed
    });
    window.dispatchEvent(event);
  }; 

  const handleAddSubmission = () => {
    // Determine the next submission number
    const existingNumbers = submissions.map((sub) =>
      parseInt(sub.id.replace('submission', ''), 10)
    );
    const nextNumber = existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 2;

    // Create a new submission
    const newId = `submission${nextNumber}`;
    const newSubmission: Submission = {
      id: newId,
      name: `Submission ${nextNumber}`,
    };

    // Update the state
    setSubmissions((prev) => [...prev, newSubmission]);

    // Optionally, update localStorage with the new submission key
    // Initialize 'submission{n}-page1' with default data or leave it empty
    // localStorage.setItem(`${newId}-page1`, JSON.stringify({ /* default data */ }));

    // Set the new submission as active
    handleSubmissionClick(newId);
  };

  return (
    <div className="flex max-md:flex-wrap">
      {/* Submission Header */}
      <div className="flex flex-col justify-center px-4 py-1.5 text-sm font-semibold leading-5 text-emerald-700 whitespace-nowrap bg-white border-t border-r border-b border-zinc-300">
        <div className="flex gap-5 justify-between py-1 pr-2 pl-4 bg-[#B5D8C9] rounded-lg">
          <div className="my-auto">Submission</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/955bf573a1901ee8e3cdcfc4ae7562b462087f674b5691fa4b88a3c0a1479d24?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
            alt="Submission Header Icon"
            className="shrink-0 w-10 aspect-[1.67]"
          />
        </div>
      </div>

      {/* Submissions and Plus Icon */}
      <div className="flex flex-auto gap-5 pr-20 bg-white border-t border-b border-zinc-300 max-md:flex-wrap">
        {/* Plus Icon Section */}
        <div className="flex gap-1 my-auto">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f237c9d2f4ffdcf7a88c6698f20235dc3da29af819abfef2c1af0ec5b2574a50?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
            alt="Icon"
            className="shrink-0 w-10 aspect-[1.67]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ede50b5035f29af24ac4c055f91feec69035e8905572744943a4c978e5ba424c?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
            alt="Add Submission"
            className="shrink-0 w-6 aspect-square plusicon cursor-pointer"
            onClick={handleAddSubmission}
          />
        </div>

        {/* Dynamic Submissions */}
        <div className="flex gap-3 justify-center items-center px-4 py-1 border-b-2 max-md:flex-wrap">
          {submissions.map((submission, index) => (
            <React.Fragment key={submission.id}>
              <div
                onClick={() => handleSubmissionClick(submission.id)}
                className={`cursor-pointer self-stretch my-auto text-sm font-semibold leading-5 text-neutral-700 ${
                  activeSubmission === submission.id ? 'border-b-2 border-emerald-700' : ''
                }`}
              >
                {submission.name}
              </div>
              {index < submissions.length - 1 && (
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
                  alt="separator"
                  className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300"
                />
              )}
            </React.Fragment>
          ))}

          {/* Additional Static Elements */}
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
            alt="separator"
            className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/271ec7200575d9e010b7a9f64adb8586b909c12a98f3d19d4a4299eb1a7964a8?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
            alt="Icon"
            className="shrink-0 self-stretch my-auto w-16 aspect-[2.63]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
            alt="separator"
            className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300"
          />

          {/* Review Section */}
          <div className="flex self-stretch">
            <div className="flex gap-2 px-2 py-1.5 rounded-md bg-orange-400 bg-opacity-50">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ae440f934e8c9d75e6d240219b3d0c01206f952eb6720137929d62580efbf50b?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
                alt="Icon"
                className="shrink-0 w-6 aspect-square"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c02cdd36d90816137cae57a958e4f89590281d75f112045cbba8a987159d1ae?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
                alt="Icon"
                className="shrink-0 my-auto aspect-[1.22] w-[17px]"
              />
            </div>
            <div className="flex gap-2 p-2 text-xs font-semibold leading-5 text-amber-900 bg-orange-400 rounded-none">
              <div>Review in progress</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/876616f7b9909ac91bf97a70ca31a2df564002696c4af017229fba6bb3ec1d61?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
                alt="Icon"
                className="shrink-0 my-auto w-2 aspect-[2]"
              />
            </div>
          </div>

          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d3f1db387893fbea3c0bb2dcdef12bd318a982e7396d93d7d9f6807e19f7363a?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
            alt="Separator"
            className="shrink-0 self-stretch my-auto w-2 aspect-[2]"
          />
        </div>
      </div>

      {/* Action Icons */}
      <div className="flex items-center gap-2 border-t border-b border-zinc-300 px-3">
        <img
          src={ResponseSheetIcon}
          alt="Response Sheet"
          className="w-4 h-4 cursor-pointer"
          onClick={handleResponseSheetClick}
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
          alt="separator"
          className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300"
        />
        <img
          src={CommentIcon}
          alt="Comment"
          className="w-4 h-4 cursor-pointer"
          onClick={handleCommentClick}
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
          alt="separator"
          className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300"
        />
        <img
          src={isSidebarOpen ? SidebarIcon : SidebarDisableIcon}
          alt="Sidebar"
          className="w-4 h-4 cursor-pointer"
          onClick={toggleSidebar}
        />
        <img
          src={isTopbarOpen ? TopbarIcon : TopbarDisableIcon}
          alt="Topbar"
          className="w-4 h-4 cursor-pointer"
          onClick={toggleTopbar}
        />
        <img
          src={RightSidebarIcon}
          alt="Right Sidebar"
          className="w-4 h-4 cursor-pointer"
          // Add onClick handler if needed
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
          alt="separator"
          className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300"
        />
        <img
          src={
            isSidebarOpen || isTopbarOpen
              ? FullscreenIcon
              : FullscreenDisableIcon
          }
          alt="Fullscreen"
          className="w-6 h-6 cursor-pointer"
          onClick={toggleFullScreen}
        />
      </div>
    </div>
  );
};

export default SubmissionBar;
